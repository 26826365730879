@import "./../styles/components.css";
@import "./../styles/base.css";

@tailwind base;
h1 {
  @apply text-[36px] font-bold leading-[44px] md:text-[64px] md:leading-[72px];
}
h2 {
  @apply text-[32px] font-bold leading-[40px] md:text-[40px]  md:leading-[48px];
}
h3 {
  @apply text-[26px] font-bold leading-[32px] md:text-[32px] md:leading-[38px];
}
h4 {
  @apply text-[22px] font-bold leading-[28px] md:text-[28px] md:leading-[32px];
}
h5 {
  @apply text-[18px] font-bold leading-[24px] md:text-[24px] md:leading-[32px];
}

@tailwind components;

@tailwind utilities;

svg {
  shape-rendering: auto;
}
.rdp-caption_dropdowns {
  @apply flex w-full flex-row justify-between;
}
.rdp-vhidden {
  @apply hidden;
}
.rdp-dropdown_month div {
  @apply hidden;
}
.rdp-dropdown_year div {
  @apply hidden;
}

.DraftEditor-root {
  @apply !min-h-[300px] py-0;
}
.DraftEditor-editorContainer {
  @apply !min-h-[300px] py-0;
}
.public-DraftEditor-content {
  @apply !min-h-[300px] py-0 !text-center;
}
.public-DraftStyleDefault-block {
  @apply !m-0;
}

input[type="date"] {
  @apply relative m-0 min-w-[180px] cursor-text;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  @apply absolute right-0 my-auto h-full w-4 cursor-pointer bg-black/10 px-4 pt-10;
}

.white input[type="date"]::-webkit-calendar-picker-indicator {
  @apply absolute right-0 my-auto h-full w-4 cursor-pointer bg-secondary-500 px-4 pt-12;
}
