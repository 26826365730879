.text-container,
.highlight-container,
.faq-heading {
  @apply flex w-full flex-col gap-4 text-base leading-[1.65rem] text-black md:gap-6;

  & h2 {
    @apply break-words text-[32px] md:text-[32px];
  }
  & h3 {
    @apply text-[28px] md:text-[28px];
  }
  & h4 {
    @apply text-[24px] md:text-[24px];
  }
  & h5 {
    @apply text-[22x] md:text-[22px];
  }
  & h6 {
    @apply text-[18px] md:text-[18px];
  }
  & a {
    @apply bg-transparent p-0.5 font-bold text-primary-800 underline decoration-primary-300  decoration-[2px] underline-offset-2 transition-all duration-150 hover:bg-primary-300/50 hover:decoration-primary-700 hover:decoration-[3px] hover:underline-offset-4;
  }
  & ul,
  & ol {
    @apply flex list-outside flex-col gap-1.5 pl-4;
  }
  ol {
    @apply ml-8 list-inside list-none  p-2;
    counter-reset: ol-counter;
  }
  ol li {
    @apply relative m-0 pb-3 text-base;
    counter-increment: ol-counter;
  }
  ol li a {
    @apply underline;
    /* counter-increment: ol-counter; */
  }
  ol li::before {
    @apply absolute -left-10  h-7 w-7 text-base font-bold;
    content: counter(ol-counter);
  }
  & ul {
    @apply list-disc;
  }

  & figure,
  & .horizontal-scroll {
    @apply -mx-6 inline-block flex overflow-x-auto md:mx-0 md:w-full;
    max-width: calc(100vw);
  }

  & .table {
    @apply mx-6 min-w-full overflow-x-auto whitespace-pre-wrap border-primary-500 lg:mx-0;
  }
  & td {
    @apply px-4;
  }
}
.image-slider {
  @apply h-auto min-h-fit w-full md:w-full;
  max-width: calc(100vw - 32px);
}

.table-responsive {
  width: calc(100vw - 32px);

  @apply overflow-x-auto  lg:w-full;
  /* max-width: calc(100% - 200px); */
  & table {
    @apply min-w-full;
  }
}
.cta-container {
  @apply flex flex-col gap-4 text-base leading-[1.35rem] text-black md:gap-6;

  & h2 {
    @apply break-words text-[32px] md:text-[40px];
  }
  & h3 {
  }
  & h4 {
    @apply text-[28px] md:text-[32px];
  }
  & h5 {
    @apply text-[24px] md:text-[28px];
  }

  & h6 {
    @apply text-[21px] md:text-[24px];
  }
  & p {
    @apply text-[18px] md:text-[20px];
  }
}

.article-container {
  @apply flex flex-col px-2 md:px-0 lg:py-4;
}

.references {
  @apply flex flex-col gap-6 border border-primary-300 bg-white px-10 py-6 text-base leading-[1.45rem] text-black;
  & h5 {
    @apply text-[24px];
  }
  & .list-container {
    @apply grid w-full grid-cols-1 gap-1 md:grid-cols-2;
  }
  & .item {
    @apply flex flex-row gap-2 border border-transparent bg-transparent px-1 py-3  transition-all duration-300 hover:border-primary-300 hover:bg-primary-300/30;
    & svg {
      @apply mt-1 h-4 w-4 text-primary-800;
    }
    & span {
      @apply flex flex-grow flex-col text-base leading-[1.25rem];
      & .title {
        @apply w-auto font-bold;
      }
    }
  }
}
.text-container {
  & li::before {
    @apply flex items-center justify-center rounded-full bg-secondary-800 text-white;
  }
  & th {
    @apply bg-secondary font-bold;
  }

  & hr {
    @apply border-primary-300;
  }
}
.highlight-container {
  @apply border border-secondary-500 bg-secondary-700 p-4 text-white lg:p-6;

  & a {
    @apply bg-transparent p-0.5 font-bold text-white underline decoration-secondary-300  decoration-[2px] underline-offset-2 transition-all duration-150 hover:bg-secondary-300/50 hover:decoration-secondary-700 hover:decoration-[3px] hover:underline-offset-4;
  }

  & li::before {
    @apply flex items-center justify-center rounded-full bg-secondary-200 text-secondary;
  }

  & hr {
    @apply border-secondary-300;
  }
  & th {
    @apply bg-secondary font-bold;
  }
}

.breadcrumb {
  @apply w-full border-b border-secondary-300/50 bg-primary-200;
  & nav {
    @apply flex;
  }
  & ul {
    @apply flex list-outside flex-wrap items-center gap-1 md:inline-flex md:gap-2;
    & li {
      @apply inline-flex items-center;
      & a,
      & span {
        @apply inline-flex text-[13px] font-medium leading-[16px] text-black/60;
      }
      & a {
        @apply font-semibold underline decoration-black/40 decoration-[1px] underline-offset-1 transition-all duration-200 hover:text-black hover:decoration-[2px] hover:underline-offset-[2px];
      }
      & span {
        @apply line-clamp-1;
      }
      & svg {
        @apply mr-1 h-3.5 w-3.5 text-secondary;
      }
    }
  }
}

.sticky-sidebar {
  align-self: start;
  @apply sticky top-24 mt-12 flex flex-col gap-2;
  & .title {
    @apply text-xl font-bold text-primary-800;
  }
  & ul {
    @apply mb-6 list-outside list-none;

    & li {
      @apply flex cursor-pointer flex-row items-center justify-start gap-1 border border-transparent bg-transparent py-0.5 transition-all duration-150 hover:border-primary-300/50 hover:bg-primary-300/20;

      & svg {
        @apply min-w-[20px] text-primary/50;
      }
      & span {
        @apply font-semibold text-primary underline decoration-primary/80 decoration-1 underline-offset-1 transition-all duration-150 hover:decoration-primary/60 hover:decoration-[3px] hover:underline-offset-2;
      }
    }
  }
}

.opinion-item {
  @apply flex flex-col items-center justify-center py-8 text-base leading-[22px] md:p-8;

  & .content {
    @apply flex flex-grow  pb-2 text-center text-[18px] font-light leading-[22px] text-black-600;

    & p {
      @apply before:text-2xl before:font-bold before:leading-[0px] before:text-primary-400 before:content-['\„'] after:text-2xl after:font-bold  after:leading-[12px] after:text-primary-400 after:content-['\"'];
    }
  }
  & .name {
    @apply text-[18px] font-bold;
  }
  & .place {
    @apply text-[16px] font-light text-black/70;
  }
  & img {
    @apply mb-2 mt-4 h-24 w-24 rounded-full border border-secondary-200/40 object-cover md:h-32 md:w-32;
  }
}
.custom-grid {
  align-items: start;
}

.sidebar-icon {
  & svg {
    @apply h-10 w-10 text-white;
  }
}
