.default-container {
  @apply mx-auto w-full px-4 py-12;
}
.container {
  @apply !max-w-screen-xl;
}

.special-bg {
  @apply bg-gradient-to-b from-primary-800 from-15% to-white to-15% md:from-30% md:to-30%;
}

.primary-content,
.secondary-content,
.tertiary-content,
.white-content {
  & a {
    @apply bg-transparent p-0.5 font-bold !no-underline transition-all duration-150;
    & strong {
      @apply font-medium;
    }
  }
}

.primary-content {
  & a {
    @apply text-primary-600 hover:bg-primary-300/50;
  }
}
.secondary-content {
  & a {
    @apply text-secondary-600 hover:bg-secondary-300/50;
  }
}
.tertiary-content {
  & a {
    @apply text-tertiary-600 hover:bg-tertiary-300/50;
  }
}
.white-content {
  & a {
    @apply text-white hover:bg-white/20;
  }
}
