h1 {
  @apply text-[32px] leading-[40px] md:text-[64px] md:leading-[78px];
}
h3 {
  @apply text-[32px] leading-[40px] md:text-[40px] md:leading-[48px];
}

h4 {
  @apply text-[28px] leading-[36px] md:text-[36px] md:leading-[42px];
}

h5 {
  @apply text-[22px] leading-[28px] md:text-[32px] md:leading-[40-px];
}
