.footer {
  & .grid-1 {
    @apply md:grid-cols-1;
  }
  & .grid-2 {
    @apply md:grid-cols-2;
  }
  & .grid-3 {
    @apply md:grid-cols-3;
  }
  & .grid-4 {
    @apply md:grid-cols-4;
  }
  & .grid-5 {
    @apply md:grid-cols-5;
  }
  & .grid-6 {
    @apply md:grid-cols-6;
  }
  & .col-1 {
    @apply md:col-span-1;
  }
  & .col-2 {
    @apply md:col-span-2;
  }
  & .col-3 {
    @apply md:col-span-3;
  }
  & .col-4 {
    @apply md:col-span-4;
  }
  & .col-5 {
    @apply md:col-span-5;
  }
  & .col-6 {
    @apply md:col-span-6;
  }
}
