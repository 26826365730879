.header-gradient-primary {
  @apply bg-header-graditent from-primary-600 to-primary-800;
  & .box-title strong {
    @apply text-primary-800;
  }
}
.header-gradient-secondary {
  @apply bg-header-graditent from-secondary-800 to-secondary;
  & .box-title strong {
    @apply text-secondary-800;
  }
}

.header-gradient-secondary-light {
  @apply bg-header-graditent from-secondary-700 to-secondary-600/70;
}

.header-gradient-tertiary {
  @apply bg-header-graditent from-tertiary-800 to-tertiary;
  & .box-title strong {
    @apply text-tertiary-800;
  }
}

.header-image {
  & .box-title strong {
    @apply text-secondary-800;
  }
}

.header-gradient-primary,
.header-gradient-secondary,
.header-gradient-tertiary,
.image-dark {
  @apply text-white;
  & .highlight-box {
    @apply rounded-lg border border-white/40 bg-black/50 text-white underline decoration-transparent decoration-2 underline-offset-0 bg-blend-darken transition-all duration-200 hover:border-2 hover:border-white/60 hover:text-white/90 hover:decoration-white hover:underline-offset-2;
  }
  & .theme-selector {
    @apply bg-white shadow-lg;
  }
}
.image-light {
  @apply text-secondary-900;
  & .highlight-box {
    @apply rounded-lg border border-secondary/40 bg-white/50 text-secondary-900 underline decoration-transparent decoration-2 underline-offset-0 bg-blend-lighten transition-all duration-200 hover:border-2 hover:border-secondary/60 hover:text-secondary-800 hover:decoration-secondary hover:underline-offset-2;
  }
  & .theme-selector {
    @apply border border-secondary-300 bg-secondary-100 shadow-lg;
  }
}

.header {
  @apply flex w-full flex-col items-center justify-center space-y-10 
    px-5 py-12 text-center md:space-y-12
    md:px-8 md:py-12 lg:space-y-14 lg:px-32 lg:py-24;
  & p.subtitle {
    @apply text-lg lg:text-xl;
  }
}
.box-title {
  @apply text-3xl font-bold leading-[1.25em] lg:text-6xl lg:leading-[1.25em];
  & strong {
    @apply bg-white px-1 py-0 font-bold md:p-1 lg:px-1.5 lg:py-1;
  }
}
.normal-title {
  @apply text-3xl font-bold leading-[1.25em] lg:text-6xl lg:leading-[1.25em];
}
.underline-title {
  @apply text-3xl font-bold leading-[1.25em] lg:text-6xl lg:leading-[1.25em];
  & strong {
    @apply pb-1.5 font-bold lg:pb-2;
    background-position: bottom left;
    background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1684860738/assets/svgs/underline.svg");
    background-repeat: repeat-x;
    background-size: 100%;
    object-fit: cover;
  }
}
.arrow-cta-area {
  @apply relative flex flex-row items-center justify-center sm:w-full md:absolute
  md:w-arrowCTA md:flex-col md:items-end;

  &.right {
    @apply right-0 md:-top-4 md:items-start;
  }
}
