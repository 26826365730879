.mobile-phone span {
  @apply flex w-full grow flex-col leading-5;
}

label.button,
a.button,
button {
  @apply relative flex cursor-pointer flex-row items-center justify-center overflow-hidden rounded-md outline outline-1 outline-transparent transition-all hover:outline-[3px] active:outline-1 active:outline-offset-2;

  &.left {
    @apply justify-start;
  }

  &.w-sm {
    @apply w-full md:w-32;
  }
  &.w-md {
    @apply w-full md:w-40;
  }
  &.w-lg {
    @apply w-full md:w-48;
  }
  &.w-xl {
    @apply w-full md:w-64;
  }
  &.w-2xl {
    @apply w-full md:w-96;
  }

  &.small {
    @apply gap-1 px-3 py-3 text-[15px] font-bold leading-[13px];
    & svg {
      @apply h-3 w-3;
    }
  }
  &.medium {
    @apply gap-1.5 px-5 py-3 text-[16px] font-bold leading-[16px];
    & svg {
      @apply h-4 w-4;
    }
  }
  &.large {
    @apply gap-2 px-7 py-3 text-[18px] font-bold leading-[16px];
    & svg {
      @apply h-5 w-5;
    }
  }

  &.solid {
    @apply text-white shadow-none duration-300 hover:shadow-lg;
  }
  &.solid-white {
    @apply shadow-none duration-300 hover:shadow-lg;
  }
  &.custom-outline {
    @apply bg-transparent duration-100;
  }
  &.custom-white-outline {
    @apply bg-white/0 text-white outline-white duration-100 hover:bg-white/10;
  }
  &.custom-text {
    @apply bg-transparent underline underline-offset-2 hover:underline-offset-4;
  }
  &.custom-white-text {
    @apply bg-transparent text-white underline underline-offset-2 hover:underline-offset-4;
  }
  &.primary {
    &.solid {
      @apply bg-gradient-to-b from-primary-900  to-primary-700 shadow-primary/50 hover:from-primary-800 hover:to-primary-600;
    }
    &.solid-white {
      @apply bg-gradient-to-b from-primary-100 to-white text-primary-800 shadow-primary-900 hover:from-primary-200 hover:to-primary-100 hover:text-primary-900;
    }
    &.custom-outline {
      @apply bg-primary/0 text-primary-800 outline-primary-800 hover:bg-primary/10 hover:text-primary-900;
    }
    &.custom-text {
      @apply text-primary-800 hover:text-primary-900;
    }
  }
  &.secondary {
    &.solid {
      @apply bg-gradient-to-b from-secondary-900  to-secondary-700 shadow-secondary/50 hover:from-secondary-800 hover:to-secondary-600;
    }
    &.solid-white {
      @apply bg-gradient-to-b from-secondary-100 to-white text-secondary-800 shadow-secondary-900 hover:from-secondary-200 hover:to-secondary-100 hover:text-secondary-900;
    }
    &.custom-outline {
      @apply bg-secondary/0 text-secondary-800 outline-secondary-800 hover:bg-secondary/10 hover:text-secondary-900;
    }
    &.custom-text {
      @apply text-secondary-800 hover:text-secondary-900;
    }
  }

  &.tertiary {
    &.solid {
      @apply bg-gradient-to-b from-tertiary-900  to-tertiary-700 shadow-tertiary/50 hover:from-tertiary-800 hover:to-tertiary-600;
    }
    &.solid-white {
      @apply bg-gradient-to-b from-tertiary-100 to-white text-tertiary-800 shadow-tertiary-900 hover:from-tertiary-200 hover:to-tertiary-100 hover:text-tertiary-900;
    }
    &.custom-outline {
      @apply bg-tertiary/0 text-tertiary-800 outline-tertiary-800 hover:bg-tertiary/10 hover:text-tertiary-900;
    }
    &.custom-text {
      @apply text-tertiary-800 hover:text-tertiary-900;
    }
  }

  &.custom-phone-bar {
    @apply px-3;
    & span {
      @apply w-full tracking-tight;
    }
  }

  /* 


  & svg {
    @apply mx-1 flex h-4 w-4 items-center justify-center stroke-current;
  }
  &.default,
  &.white {
    @apply shadow-lg hover:shadow-none;
  }
  &.default,
  &.white {
    @apply bg-gradient-to-b;
  }
  &.default {
    @apply text-white;
  }
  &.text {
  }
  &.white {
    @apply from-white outline-white;
  }
  &.text {
    @apply underline underline-offset-4;
  }
  &.primary {
    &.default {
      @apply from-primary-700 to-primary-800 shadow-primary-700/40 outline-primary-900;
    }
    &.white {
      @apply to-primary-100 text-primary-800 shadow-primary-700/40 outline-primary-200;
    }
    &.invert,
    &.text {
      @apply text-primary-800 hover:text-primary-900;
    }
    &.invert {
      @apply outline-primary-700 hover:outline-primary-800;
    }
  }
  &.secondary {
    &.default {
      @apply from-secondary-700 to-secondary-800 shadow-secondary-700/40 outline-secondary-900;
    }
    &.white {
      @apply to-secondary-100 text-secondary-800 shadow-secondary-700/40 outline-secondary-200;
    }
    &.invert,
    &.text {
      @apply text-secondary-800 hover:text-secondary-900;
    }
    &.invert {
      @apply outline-secondary-700 hover:outline-secondary-800;
    }
  }
  &.alternative {
    &.default {
      @apply from-alternative-700 to-alternative-800 shadow-alternative-700/40 outline-alternative-900;
    }
    &.white {
      @apply to-alternative-100 text-alternative-800 shadow-alternative-700/40 outline-alternative-200;
    }
    &.invert,
    &.text {
      @apply text-alternative-800 hover:text-alternative-900;
    }
    &.invert {
      @apply outline-alternative-700 hover:outline-alternative-800;
    }
  }
  &.extra {
    &.default {
      @apply from-extra-700 to-extra-800 shadow-extra-700/40 outline-extra-900;
    }
    &.white {
      @apply to-extra-100 text-extra-800 shadow-extra-700/40 outline-extra-200;
    }
    &.invert,
    &.text {
      @apply text-extra-800 hover:text-extra-900;
    }
    &.invert {
      @apply outline-extra-700 hover:outline-extra-800;
    }
  }
  &.red {
    &.default {
      @apply from-red-700 to-red-800 shadow-red-700/40 outline-red-900;
    }
    &.white {
      @apply to-red-100 text-red-800 shadow-red-700/40 outline-red-200;
    }
    &.invert,
    &.text {
      @apply text-red-800 hover:text-red-900;
    }
    &.invert {
      @apply outline-red-700 hover:outline-red-800;
    }
  }
  &.green {
    &.default {
      @apply from-green-700 to-green-800 shadow-green-700/40 outline-green-900;
    }
    &.white {
      @apply to-green-100 text-green-800 shadow-green-700/40 outline-green-200;
    }
    &.invert,
    &.text {
      @apply text-green-800 hover:text-green-900;
    }
    &.invert {
      @apply outline-green-700 hover:outline-green-800;
    }
  }
  &.gray {
    &.default {
      @apply from-black-700 to-black-800 shadow-black-700/40 outline-black-900;
    }
    &.white {
      @apply to-black-100 text-black-800 shadow-black-700/40 outline-black-200;
    }
    &.invert,
    &.text {
      @apply text-white hover:text-white/90;
    }
    &.invert {
      @apply outline-white hover:outline-white/90;
    }
  }
 */
}

.custom-basic-cta {
  & .custom-white-outline {
    @apply !bg-tertiary-700 !bg-opacity-100 !shadow-xl !outline-white/70 hover:!bg-tertiary-600 hover:!outline-white;
  }
}
