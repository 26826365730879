.custom-navbar {
  @apply absolute flex w-full justify-center;
  & .list {
    @apply m-0 flex list-none rounded-[6px] p-1;

    & .link {
      @apply block select-none rounded-[4px] px-3 py-2 text-[18px] font-medium leading-none  no-underline outline-none focus:shadow-[0_0_0_2px];
    }
  }
  & .indicator {
    @apply top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease] data-[state=hidden]:animate-fadeOut data-[state=visible]:animate-fadeIn;
    & div {
      @apply relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px];
    }
  }
  & .menu-area {
    @apply absolute left-0 top-full flex w-full justify-center perspective-[2000px];
    & .viewport {
      @apply relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] bg-white transition-[width,_height] duration-100 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn sm:w-[var(--radix-navigation-menu-viewport-width)];
    }
  }
  & .trigger {
    @apply flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[18px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px];
    & svg {
      @apply relative top-[1px] transition-transform duration-100 ease-in group-data-[state=open]:-rotate-180;
    }
  }
  & .content {
    @apply absolute left-0 top-0 w-full data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft sm:w-auto;
    & ul {
      @apply m-0 grid list-none gap-2.5 p-4 md:w-[400px] lg:w-[600px];
    }
    & ul.big {
      @apply w-full md:w-[600px] lg:w-[900px];
    }
    & ul.col-2 {
      @apply grid-cols-2;
    }
    & ul.col-3 {
      @apply grid-cols-3;
    }
    & ul.col-4 {
      @apply grid-cols-4;
    }
    & a.row-2 {
      @apply row-span-2;
    }
    & a.row-3 {
      @apply row-span-3;
    }
    & a.row-4 {
      @apply row-span-4;
    }
    & a.row-5 {
      @apply row-span-5;
    }
    & a.row-6 {
      @apply row-span-6;
    }
    & .common {
      @apply relative flex h-full w-full select-none flex-col justify-start overflow-hidden rounded-[4px] border border-secondary-200 bg-secondary-100 p-2 text-secondary-900 no-underline outline-none transition-colors duration-100 hover:bg-secondary-200 focus:shadow-[0_0_0_2px];
      & p {
        @apply text-[20px] font-bold leading-[1.2];
      }
      & span {
        @apply text-[14px] leading-[1.2];
      }
      & .icon {
        @apply absolute -bottom-2 -right-2  h-[90%] w-auto rounded-[4px];
        & svg {
          @apply h-full w-auto opacity-10;
        }
      }
      & .asset-icon {
        @apply flex min-h-[60px] min-w-[60px] items-center justify-center rounded-[4px] bg-secondary text-white;
        & svg {
          @apply h-10 w-10;
        }
      }
    }
    & .funeral-type {
      @apply flex h-full w-full cursor-pointer select-none flex-row items-start justify-start gap-x-2 rounded-[4px] border border-secondary-200 bg-secondary-100 p-2 no-underline outline-none transition-colors duration-100 hover:bg-secondary-200 focus:shadow-[0_0_0_2px];
      & .icon {
        @apply flex min-h-[60px] min-w-[60px] items-center justify-center rounded-[4px] bg-secondary text-white;
        & svg {
          @apply h-10 w-10;
        }
      }
      & .content {
        @apply relative flex flex-col items-start justify-start gap-1;

        & .title {
          @apply text-[20px] font-bold leading-[1.2];
        }
        & .content {
          @apply text-[14px] font-normal leading-[1.2];
        }
        & .price {
          @apply text-[16px] font-normal leading-[1.2];
          & strong {
            @apply font-bold;
          }
        }
      }
    }
  }
}

.nav-bar-black,
.nav-bar-primary,
.nav-bar-secondary,
.nav-bar-tertiary,
.nav-bar-white-primary,
.nav-bar-white-secondary,
.nav-bar-white-tertiary {
  & .list {
    @apply text-white;
  }
  & .indicator {
    & div {
      @apply bg-white;
    }
  }
  & .menu-area {
    & .viewport {
      @apply bg-white;
    }
  }
}

.nav-bar-primary,
.nav-bar-primary[data-expanded="false"] {
  @apply bg-primary-800/80 shadow-none;
}
.nav-bar-primary[data-expanded="true"] {
  @apply flex bg-primary-800 bg-opacity-90 shadow-2xl backdrop-blur-md;
}

.nav-bar-secondary,
.nav-bar-secondary[data-expanded="false"] {
  @apply bg-secondary-800/80 shadow-none;
}
.nav-bar-secondary[data-expanded="true"] {
  @apply flex bg-secondary-800 bg-opacity-90 shadow-2xl backdrop-blur-md;
}

.nav-bar-black,
.nav-bar-black[data-expanded="false"] {
  @apply bg-black/60 shadow-none;
}
.nav-bar-black[data-expanded="true"] {
  @apply flex bg-black bg-opacity-60 shadow-2xl backdrop-blur-md;
}

.nav-bar-tertiary,
.nav-bar-tertiary[data-expanded="false"] {
  @apply bg-tertiary-800/80 shadow-none;
}
.nav-bar-tertiary[data-expanded="true"] {
  @apply flex bg-tertiary-800 bg-opacity-90 shadow-2xl backdrop-blur-md;
}
.nav-bar-white-primary[data-expanded="false"] {
  @apply bg-primary-800 shadow-none;
}
.nav-bar-white-primary[data-expanded="true"] {
  @apply flex bg-primary-800 bg-opacity-90 shadow-2xl backdrop-blur-md;
}
.nav-bar-white-secondary[data-expanded="false"] {
  @apply bg-secondary-800 shadow-none;
}
.nav-bar-white-secondary[data-expanded="true"] {
  @apply flex bg-secondary-800 bg-opacity-90 shadow-2xl backdrop-blur-md;
}
.nav-bar-white-tertiary[data-expanded="false"] {
  @apply bg-tertiary-800 shadow-none;
}
.nav-bar-white-tertiary[data-expanded="true"] {
  @apply flex bg-tertiary-800 bg-opacity-90 shadow-2xl backdrop-blur-md;
}

.nav-bar {
  @apply fixed z-[1] flex h-auto w-full bg-white/0 transition-all duration-100;
  & .list {
    @apply text-white;
  }
}

.phone-bar {
  @apply fixed -bottom-3 z-30 flex h-[92px] w-[360px] flex-col items-center justify-start rounded-md border border-secondary-300 bg-white/90 p-2 shadow-2xl backdrop-blur-md transition-transform duration-500 md:hidden;
}
.phone-bar[data-extended="true"] {
  @apply h-[104px];
}
.phone-bar[data-expanded="true"] {
  @apply translate-y-[0%];
}
.phone-bar[data-expanded="false"] {
  @apply translate-y-[100%];
}

.grid-desktop-navbar {
  @apply relative grid h-[340px] w-[930px] cursor-pointer grid-cols-4 grid-rows-6 gap-2.5  rounded-[4px] bg-white p-4;
  & a {
    @apply relative flex flex-col gap-4 overflow-hidden  rounded-[4px] border border-secondary-200 bg-secondary-100 transition-all duration-300 hover:border-secondary-300 hover:bg-secondary-200;
    & .content {
      @apply relative flex w-full flex-col gap-2 px-2 pt-3;
      & p {
        @apply text-[14px] leading-[18px];
      }
      & .title {
        @apply text-[19px] font-bold leading-[16px];
      }
      & ul {
        @apply m-0  ml-6 grid w-[90%] list-disc columns-3 grid-cols-3 gap-0.5 p-0 text-[14px];
      }
    }
  }
}
.grid-desktop-articles {
  @apply relative grid w-[930px] cursor-pointer  grid-cols-4 gap-2.5 rounded-[4px] p-4;
  & .item {
    @apply relative flex flex-col rounded-[4px] border border-secondary-200 bg-secondary-100 transition-all duration-300 hover:border-secondary-300 hover:bg-secondary-200;
  }
  & .count {
    @apply relative flex flex-col  items-center justify-center rounded-[4px] border border-secondary-700 bg-secondary text-white transition-all duration-300 hover:border-secondary-800 hover:bg-secondary-700;
  }
}
