.page-content,
.article-content,
.text-container {
  & table {
    line-height: 0.75rem !important;
    & th {
      @apply py-4 text-sm font-normal;
    }
    & th,
    & td {
      @apply border border-secondary px-0 py-2 text-sm;
    }
    & td {
      @apply w-auto p-2 px-2 text-center;
    }
    & td:first-child {
      @apply h-12 !w-auto text-left;
    }
    & th {
      @apply sticky top-0 bg-secondary px-1 py-4 font-bold text-white;
    }
    & tr:nth-child(even) {
      @apply bg-primary-300/70;
    }
    & .true {
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685045595/true.svg");
    }
    & .false {
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685045595/false.svg");
    }
  }
}
