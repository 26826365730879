.checkbox {
  @apply flex w-full cursor-pointer flex-row items-center gap-1;
  & button {
    @apply flex h-5 w-5 appearance-none items-center justify-center rounded-[4px] border border-secondary-300  bg-secondary-100 outline-none 
    data-[state=checked]:border-secondary-800 data-[state=checked]:bg-secondary-600;
  }

  & button:disabled {
    @apply bg-black-400;
  }

  & label {
    @apply text-[13px] font-semibold leading-none;
  }
  & span {
    @apply text-white;
  }
}
